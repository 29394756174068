import Vue from "vue";
import VueRouter from "vue-router";
import WelcomePage from "@/views/MainPages/WelcomePage.vue";
import LandingPage from "@/views/MainPages/LandingPage.vue";
import TravelogsList from "@/views/ChildPages/TravelogsList.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Welcome Page",
    component: WelcomePage,
  },

  {
    path: "/LandingPage",
    name: "Landing Page",
    component: LandingPage,
    children: [
      {
        path: "/TravelLogsList",
        name: "TravelLogsList",
        component: TravelogsList,
      },
    ],
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
