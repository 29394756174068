<template>
  <v-app>
    <div id="trvlg-login-page">
      <SnackBar :SnackBarComponent="SnackBarComponent" />
      <div id="particles-js"></div>
      <div class="overlay-container">
        <v-container fluid class="pa-0 d-flex justify-center">
          <v-card
            elevation="0"
            :width="1000"
            :height="620"
            style="border-radius: 100px 0 100px 0"
          >
            <v-row align="center">
              <v-col cols="12" lg="6" md="6" sm="6" style="max-width: 460px">
                <v-carousel
                  cycle
                  interval="1200"
                  height="620"
                  hide-delimiters
                  :show-arrows="false"
                  style="border-radius: 100px 0 0 0"
                >
                  <v-carousel-item
                    v-for="(slide, i) in slides"
                    :key="i"
                    :src="slide"
                  >
                  </v-carousel-item>
                </v-carousel>
              </v-col>
              <v-col cols="12" lg="6" md="6" sm="6">
                <div>
                  <v-img
                    class="mx-auto mb-2"
                    src="@/assets/new-trvlg-logo-dark.png"
                    :width="200"
                  />
                  <h3 class="text-center mb-5">Welcome To Agent Portal</h3>
                  <v-form ref="loginForm" v-model="valid">
                    <div class="d-flex justify-center">
                      <v-text-field
                        rounded
                        dense
                        outlined
                        label="Enter Your Email"
                        v-model="userEmail"
                        :rules="emailRules"
                        required
                        color="black"
                        append-icon="mdi-email"
                        type="text"
                        @keyup.enter="validate_sign_in()"
                        style="font-size: 13px; max-width: 430px"
                      >
                      </v-text-field>
                    </div>
                    <div v-show="otpVisible == 0">
                      <v-btn
                        block
                        rounded
                        color="#7B66FF"
                        dark
                        class="mt-4 elevation-0 text-capitalize send-otp-button pa-5"
                        :loading="loading1"
                        @click="validate_sign_in()"
                        >Send OTP</v-btn
                      >
                    </div>
                    <div v-show="otpVisible == 1">
                      <div class="text-left font-weight-medium">
                        Please enter the OTP sent to your email
                      </div>
                      <v-row no-gutters class="mt-2">
                        <v-otp-input
                          light
                          v-model="otpInput"
                          ref="otpForm"
                          length="6"
                          class="black--text"
                        ></v-otp-input>
                        <div class="ml-2 font-weight-medium">
                          <v-btn
                            outlined
                            rounded
                            dense
                            @click="generate_otp(2)"
                            class="text-capitalize mt-2 mb-2"
                          >
                            <v-icon color="black" class="pr-2"
                              >mdi-refresh</v-icon
                            >Resend OTP</v-btn
                          >
                        </div>
                      </v-row>
                      <div v-if="otpVisible == 1">
                        <v-btn
                          block
                          rounded
                          style="color: white"
                          class="mt-4 text-capitalize login-btn pa-5"
                          :loading="loading1"
                          @click="login_with_otp()"
                          >Login</v-btn
                        >
                      </div>
                    </div>
                  </v-form>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </div>
    </div>
  </v-app>
</template>

<script>
import { Auth } from "aws-amplify";
import SnackBar from "@/components/Extras/SnackBar.vue";
import { LoginPageBgParticles } from "@/mixins/Extras/Particles.js";
export default {
  mixins: [LoginPageBgParticles],
  props: {
    openLoginPageDialog: Boolean,
  },
  components: {
    SnackBar,
  },
  data() {
    return {
      valid: true,
      userEmail: "",
      otpVisible: 0,
      otpRef: "otpField",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      otpInput: "",
      loading1: false,
      SnackBarComponent: {},
      slides: [
        require("@/assets/travel-1.jpg"),
        require("@/assets/travel-2.jpg"),
        require("@/assets/travel-3.jpg"),
        require("@/assets/travel-4.jpg"),
        require("@/assets/travel-5.jpg"),
        require("@/assets/travel-6.jpg"),
        require("@/assets/travel-7.jpg"),
        require("@/assets/travel-8.jpg"),
      ],
    };
  },
  mounted() {
    this.initParticles();
  },

  methods: {
    validate_sign_in() {
      if (this.$refs.loginForm.validate()) {
        this.loading1 = true;
        this.generate_otp();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: "Enter valid email",
          timeout: 5000,
          Top: true,
        };
      }
      //  // var valid = true;
      //   if (this.userEmail == "") {
      //    // valid = false;
      //     this.SnackBarComponent = {
      //       SnackbarVmodel: true,
      //       SnackbarColor: "red",
      //       SnackbarText: "Email  Is Required",
      //       timeout: 5000,
      //       Top: true,
      //     };
      //   }
      //   if (valid) {
      //     if (val == "otp") {
      //       this.generate_otp();
      //     } else {
      //       this.login_with_otp();
      //     }
      //   }
    },

    async generate_otp(Toggle) {
      if (Toggle === 2) {
        this.otpInput = "";
      }
      try {
        const user = await Auth.signIn(this.userEmail)
          .then((user) => {
            this.userDetails = user;
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              SnackbarText: "OTP Sent Successfully",
              timeout: 5000,
              Top: true,
            };
            this.otpVisible = 1;
            this.loading1 = false;
          })
          .catch((err) => {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              SnackbarText: "Couldn't find your account!!",
              timeout: 5000,
              Top: true,
            };
            this.loading1 = false;
          });
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: error.message,
          timeout: 5000,
          Top: true,
        };
        this.loading1 = false;
      }
    },

    focusOnOTPField() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs[this.otpRef].focus();
        }, 100);
      });
    },

    async login_with_otp() {
      this.loading1 = true;
      try {
        await Auth.sendCustomChallengeAnswer(this.userDetails, this.otpInput);
        await Auth.currentAuthenticatedUser()
          .then((user) => {
            this.$store.commit("SetAuth", true);
            this.$store.commit("SetUserEmail", this.userEmail);
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              SnackbarText: "Login Successfully!!",
              timeout: 5000,
              Top: true,
            };

            this.$router.push("/LandingPage");
            this.loading1 = false;
          })
          .catch((err) => {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              SnackbarText: "Invalid OTP",
              timeout: 5000,
              Top: true,
            };
            this.loading1 = false;
            this.otpInput = "";
            // this.$refs.otp.focus();
          });
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: "Invalid OTP",
          timeout: 5000,
          Top: true,
        };
        this.loading1 = false;
      }
    },
  },
};
</script>

<style scoped>
.overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#particles-js {
  background: black;
  background-size: cover !important;
  height: 100vh !important;
  width: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden !important;
}
</style>
