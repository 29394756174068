import { get_agent } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";

export const GetCurrentUser = {
  data: () => ({}),
  methods: {
    async GetCurrentUserMethod() {
      try {
        let result = await API.graphql(
          graphqlOperation(get_agent, {
            input: {
              agent_email: this.$store.getters.GetUserEmail,
            },
          })
        );
        const userData = JSON.parse(result.data.get_agent).Data[0];
        this.$store.commit("SetUserDetails", userData);
        this.$router.push("/TravelLogsList");
        // return JSON.parse(result.data.get_agent).Data[0];
      } catch (error) {
        console.log("Error", error)
      }
    },
  },
};
