<template>
  <div id="pov-card-app">
    <SnackBar :SnackBarComponent="SnackBarComponent" />
    <AddPostToPovDialog
      :AddPostToPovDialog="AddPostToPovDialog"
      :StoreObj="StoreObj"
      :StorePOVsObj="StorePOVsObj"
      @clicked="AddPostToPovDialogEmit"
    />
    <DeletePovPostDialog
      :DeletePovPostDialog="DeletePovPostDialog"
      :StoreObj="StoreObj"
      :StorePOVsObj="StorePOVsObj"
      :StorePostObj="StorePostObj"
      @clicked="DeleteDialogEmit"
    />
    <MaximizePovPost
      :MaximizePovPost="MaximizePovPost"
      :currentImgIndex="currentImgIndex"
      :maximizePovPostList="maximizePovPostList"
      @clicked="ClosePovPostDialog"
    />

    <section id="pov-sec1">
      <v-container fluid class="pa-0">
        <v-card :width="1536" elevation="0" class="mx-auto mt-5">
          <v-row class="ml-16 mr-16 align-center pov-card-sec1-row">
            <v-col
              cols="12"
              lg="6"
              md="6"
              sm="6"
              xs="12"
              class="pov-card-sec1-cols"
            >
              <div class="pov-card-app-logo-div">
                <v-img
                  src="@/assets/new-trvlg-logo-dark.png"
                  :width="195"
                ></v-img>
                <div class="user-info-div">
                  <p class="black--text">
                    {{ $store.getters.GetUserDetails.agent_name }}
                  </p>
                  <p class="black--text">
                    {{ $store.getters.GetUserDetails.agent_email }}
                  </p>
                </div>
              </div>
            </v-col>

            <v-col
              cols="12"
              lg="6"
              md="6"
              sm="6"
              xs="12"
              class="text-end pov-card-sec1-cols"
            >
              <v-btn
                class="text-capitalize mr-7 logout-btn"
                rounded
                @click="logout()"
              >
                Logout
              </v-btn>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="black" size="50"
                    >mdi-account-circle</v-icon
                  >
                </template>
                <span class="pa-0">
                  <p>Name: {{ $store.getters.GetUserDetails.agent_name }}</p>
                  <p>Email: {{ $store.getters.GetUserDetails.agent_email }}</p>
                </span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </section>

    <section id="pov-sec3">
      <v-container
        fluid
        class="pa-0 pl-16 pr-16 mt-10 mb-10 pov-card-app-sec3-cont1"
      >
        <v-card :width="1536" class="pov-card3 mx-auto" elevation="0">
          <v-row class="align-items">
            <v-col
              cols="12"
              lg="6"
              md="6"
              sm="6"
              xs="12"
              class="pov-card-app-sec3-cols"
            >
              <div>
                <p class="display-3" style="font-weight: 400">
                  {{ StorePOVsObj.pov_name }}
                </p>
              </div>
            </v-col>

            <v-col
              cols="12"
              lg="6"
              md="6"
              sm="6"
              xs="12"
              class="text-end pov-card-app-sec3-cols mb-10"
            >
              <v-btn
                rounded
                text
                outlined
                class="text-capitalize mr-5 pov-card-app-back-btn"
                @click="ViewPostsOfPovsEmit(1)"
                ><v-icon class="mr-1" size="15"> mdi-arrow-left </v-icon>
                Back
              </v-btn>
              <v-btn
                rounded
                text
                outlined
                class="text-capitalize"
                @click="AddPostToPovDialog = true"
                >Upload
                <v-icon class="ml-1" size="15">mdi-image-multiple</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-container fluid class="pa-0">
            <p class="display-5 text-justify">
              {{ StorePOVsObj.pov_notes }}
            </p>
          </v-container>

          <div class="pov-cards-div">
            <div v-for="(post, index) in povPostList" :key="index">
              <!--
              Type 1 to get (Delete) button while hovering
               <v-card
                :width="300"
                class="pov-img-card pa-1 ma-1"
                @mouseenter="handleMouseEnter(post)"
                @mouseleave="handleMouseLeave"
                
                
              >
                <v-img height="150px" :src="post.post_url">
                  <div
                    class="additional-buttons"
                    v-if="isHovered(post)"
                    @click.stop
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          color="white"
                          icon
                          class="hover-pov-btn"
                          @click="deletePovPost(post, 'DELETE')"
                          v-on="on"
                          ><v-icon size="28">mdi-delete-circle</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </div>
                </v-img>
              </v-card> 
              Type2
              -->
              <v-card
                :width="300"
                class="pov-img-card pa-1 ma-1"
                @click="maximizePovPostMethod(index)"
              >
                <v-hover v-slot="{ hover }">
                  <v-img height="150px" :src="post.post_url">
                    <div class="additional-buttons" v-if="hover">
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            color="white"
                            icon
                            class="hover-pov-btn"
                            @click.stop="deletePovPost(post.post_id)"
                            v-on="on"
                          >
                            <v-icon size="28">mdi-delete-circle</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                    </div>
                  </v-img>
                </v-hover>
              </v-card>
            </div>
          </div>
          <!-- <p v-show="povPostList.length ===0">No posts found!!</p> -->
        </v-card>
      </v-container>
    </section>
  </div>
</template>

<script>
import { GetPostsByPOVS } from "@/mixins/TravelogsList/GetPostsByPOVS.js";
import SnackBar from "@/components/Extras/SnackBar.vue";
import AddPostToPovDialog from "@/components/TravelogsList/Dialogs/AddPostToPovDialog.vue";
import DeletePovPostDialog from "@/components/TravelogsList/Dialogs/DeletePovPostDialog.vue";
import MaximizePovPost from "@/components/TravelogsList/Dialogs/MaximizePovPost.vue";
export default {
  props: {
    StorePOVsObj: Object,
    StoreObj: Object,
  },
  components: {
    SnackBar,
    AddPostToPovDialog,
    DeletePovPostDialog,
    MaximizePovPost,
  },
  mixins: [GetPostsByPOVS],
  data: () => ({
    SnackBarComponent: {},
    APPROVED: false,
    CardHeight: 0,
    AddPostToPovDialog: false,
    maximizePovPostList: [],
    povPostList: [],
    hoveredCards: [],
    StorePostObj: "",
    currentImgIndex: undefined,
    DeletePovPostDialog: false,
    MaximizePovPost: false,
    renderComp: true,
  }),
  async mounted() {
    this.CardHeight = window.innerHeight - 160;
    this.povPostList = await this.GGetPostsByPOVSMethod(
      this.StorePOVsObj.pov_id
    );
  },
  methods: {
    ViewPostsOfPovsEmit(Toggle) {
      this.$emit("clicked", Toggle);
    },

    async AddPostToPovDialogEmit(Toggle) {
      this.AddPostToPovDialog = false;
      if (Toggle == 2) {
        this.povPostList = await this.GGetPostsByPOVSMethod(
          this.StorePOVsObj.pov_id
        );
      }
    },

    deletePovPost(item) {
  
      this.StorePostObj = item;
      this.DeletePovPostDialog = true;
    },

    async DeleteDialogEmit(Toggle) {
      this.DeletePovPostDialog = false;
      if (Toggle === 2) {
        this.povPostList = await this.GGetPostsByPOVSMethod(
          this.StorePOVsObj.pov_id
        );
      }
    },

    maximizePovPostMethod(index) {
      this.maximizePovPostList = this.povPostList.map((item) => {
        if (item.post_url !== "") {
          return item.post_url;
        }
      });
      this.MaximizePovPost = true;
      this.currentImgIndex = index;
    },

    ClosePovPostDialog(Toggle) {
      if (Toggle === 1) {
        this.MaximizePovPost = false;
      }
    },

    logout() {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "red",
        SnackbarText: "Logout Successfull",
      };
      setTimeout(() => {
        this.$store.commit("SetUserEmail", "");
        this.$store.commit("SetUserDetails", {});
        this.$router.push("/");
      }, 900);
    },
  },
};
</script>

<!-- <style scoped>
#pov-card-app {
  font-family: "Poppins", sans-serif;
}

.logout-btn {
  background: linear-gradient(to bottom, #33bbc5, #e91e63);
  color: white;
}

.user-info-div {
  line-height: 0;
  position: relative;
  left: 73px;
}

.user-info-div p {
  font-size: 13px;
  font-weight: 500;
}

.pov-card3 {
  border-radius: 20px;
  background-color: #dfe6f0;
  padding: 70px;
}

.pov-cards-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.pov-img-card {
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.pov-img-card:hover {
  transform: scale(0.9);
}

.additional-buttons {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.hover-pov-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  margin: 5px;
  border-radius: 100px;
}

.pov-img-card:hover .additional-buttons {
  opacity: 1;
}
</style> -->
