/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const get_agent = /* GraphQL */ `
  query Get_agent($input: GetAgentInput) {
    get_agent(input: $input)
  }
`;
export const list_my_draft_travelogues = /* GraphQL */ `
  query List_my_draft_travelogues($input: ListTravelogueInput) {
    list_my_draft_travelogues(input: $input)
  }
`;
export const list_pending_approval_travelogues = /* GraphQL */ `
  query List_pending_approval_travelogues($input: ListPendingTravelogueInput) {
    list_pending_approval_travelogues(input: $input)
  }
`;
export const list_rejected_travelogues = /* GraphQL */ `
  query List_rejected_travelogues($input: ListRejectedTravelogueInput) {
    list_rejected_travelogues(input: $input)
  }
`;
export const list_approved_agent_travelogues = /* GraphQL */ `
  query List_approved_agent_travelogues($input: ListApprovedTravelogueInput) {
    list_approved_agent_travelogues(input: $input)
  }
`;
export const list_pov = /* GraphQL */ `
  query List_pov($input: ListPovInput) {
    list_pov(input: $input)
  }
`;
export const list_post = /* GraphQL */ `
  query List_post($input: ListPostInput) {
    list_post(input: $input)
  }
`;
export const travelogS3URL_for_download = /* GraphQL */ `
  query TravelogS3URL_for_download($input: S3URLDownloadInput) {
    travelogS3URL_for_download(input: $input)
  }
`;
export const travelogS3URL = /* GraphQL */ `
  query TravelogS3URL($input: s3UrlInput) {
    travelogS3URL(input: $input)
  }
`;
export const list_approved_travelogues = /* GraphQL */ `
  query List_approved_travelogues {
    list_approved_travelogues
  }
`;
