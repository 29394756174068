<template>
  <v-app id="pov-app">
    <SnackBar :SnackBarComponent="SnackBarComponent" />
    <LoadingBuffer :LoadingBufferAnimation="LoadingBufferAnimation" />
    <CreatePov
      :povDialog="povDialog"
      :StoreObj="StoreObj"
      :StorePOVsObj="StorePOVsObj"
      @toSort="CreatePovEmit"
    />
    <PublishTravelog
      :PublishTravelogDialog="PublishTravelogDialog"
      :StoreObj="StoreObj"
      @clicked="PublishTravelogDialogEmit"
      @publishMessage="PublishTravelogMessage"
    />
    <DeletePovDialog
      :DeletePovDialog="DeletePovDialog"
      :StoreObj="StoreObj"
      :StorePOVsObj="StorePOVsObj"
      @clicked="delete_pov_dialog"
    />
    <ViewPostOfPOVs
      v-if="current_view == 'VIEW_POSTS'"
      :StorePOVsObj="StorePOVsObj"
      :StoreObj="StoreObj"
      @clicked="ViewPostsOfPovsEmit"
    />
    <v-card flat v-if="current_view == 'VIEW_POVS'">
      <section id="pov-sec1">
        <v-container fluid class="pa-0">
          <v-card :width="1536" elevation="0" class="mx-auto">
            <v-card-text>
              <v-row class="ml-16 mr-16 align-center pov-sec1-row">
                <v-col
                  cols="12"
                  lg="6"
                  md="6"
                  sm="6"
                  xs="12"
                  class="pov-sec1-cols"
                >
                  <div class="pov-sec1-img-info-div">
                    <v-img
                      src="@/assets/new-trvlg-logo-dark.png"
                      :width="195"
                    ></v-img>
                    <div class="user-info-div">
                      <p class="black--text">
                        {{ $store.getters.GetUserDetails.agent_name }}
                      </p>
                      <p class="black--text">
                        {{ $store.getters.GetUserDetails.agent_email }}
                      </p>
                    </div>
                  </div>
                </v-col>

                <v-col
                  cols="12"
                  lg="6"
                  md="6"
                  sm="6"
                  class="text-end pov-sec1-cols"
                >
                  <v-btn
                    class="text-capitalize white--text mr-7 logout-btn"
                    rounded
                    @click="logout()"
                  >
                    Logout
                  </v-btn>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="black" size="50"
                        >mdi-account-circle</v-icon
                      >
                    </template>
                    <span class="pa-0">
                      <p>
                        Name: {{ $store.getters.GetUserDetails.agent_name }}
                      </p>
                      <p>
                        Email: {{ $store.getters.GetUserDetails.agent_email }}
                      </p>
                    </span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-container>
      </section>

      <section id="pov-sec2">
        <v-container fluid class="pl-16 pr-16">
          <v-card
            :width="1536"
            :height="400"
            class="mx-auto pov-card2 align-center"
          >
            <v-img
              :width="1536"
              :height="400"
              class="pov-card2-img mx-auto"
              :src="StoreObj.travelogue_image"
            >
              <div class="opacity-pov-div">
                <v-row class="fill-height">
                  <v-col
                    cols="12"
                    lg="6"
                    md="6"
                    sm="6"
                    xs="12"
                    class="pov-sec2-cols"
                  >
                    <div>
                      <p
                        class="text-capitilize display-1 font-weight-medium sec2Card-p"
                      >
                        {{ StoreObj.travelogue_name }}
                      </p>
                      <p class="text-lowercase text-white sec2Card-p">
                        {{ StoreObj.travelogue_description }}
                      </p>

                      <div
                        v-if="
                          StoreObj.travelogue_availability === 'DRAFT' ||
                          StoreObj.travelogue_availability === 'REJECTED'
                        "
                      >
                        <v-btn
                          class="text-capitalize"
                          v-if="publishMessageDisp != 'SUCCESS'"
                          rounded
                          @click="CheckItemMethod()"
                          >Publish
                          <v-icon size="20" class="ml-1">
                            mdi-send-circle-outline
                          </v-icon>
                        </v-btn>
                        <v-chip
                          v-else
                          rounded
                          class="align-center justify-center pa-5"
                        >
                          Sent For Approval<v-icon size="20" class="ml-1"
                            >mdi-check-circle</v-icon
                          >
                        </v-chip>
                      </div>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    class="text-end pov-sec2-cols"
                    lg="6"
                    md="6"
                    sm="6"
                    xs="12"
                  >
                    <div>
                      <v-btn
                        text
                        class="text-capitalize sec2-btn pov-app-back-btn mr-3"
                        rounded
                        @click="TravelogueCardEmit(1)"
                      >
                        <v-icon class="mr-1" size="15"> mdi-arrow-left </v-icon>
                        Back
                      </v-btn>
                      <v-btn
                        text
                        class="text-capitalize sec2-btn"
                        rounded
                        @click.stop="
                          (povDialog = true), (StorePOVsObj.action = 'CREATE')
                        "
                      >
                        Create POV <v-icon size="15">mdi-plus</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-img>
          </v-card>
        </v-container>
      </section>

      <section id="pov-sec3">
        <v-container fluid class="pa-0 pl-16 pr-16 mt-10 mb-10">
          <v-card :width="1536" class="pov-card3 mx-auto" elevation="0">
            <div class="pov-sec3-head-search-div">
              <p class="display-3" style="font-weight: 400">Point of visits</p>
              <v-text-field
                v-model="SearchPov"
                outlined
                rounded
                dense
                append-icon="mdi-magnify"
                class="text-capitalize pov-search-field"
              >
                <template #label>
                  <span style="font-size: 12px">Search For POV's</span>
                </template>
              </v-text-field>
            </div>
            <div class="pov-cards-div d-flex justify-center">
              <!-- <div
                  v-if="ListPOVsOfTravelogue.length === 0"
                  class="no-povs-message"
                >
                  No POV's found
                </div>
                <div v-if="SearchPov !== ''" class="no-povs-message">
                  No matching POV's found for '{{ SearchPov }}'
                </div> -->

              <p v-if="listPovArrayLen < 1">{{ noPovMessage }}</p>
              <div
                v-for="(povs, idx) in SearchPov != ''
                  ? FilteredPOV
                  : POVScreated"
                :key="idx"
              >
                <v-card
                  class="CustomizePovCard"
                  :width="300"
                  :height="200"
                  style="border-radius: 20px; margin: 5px"
                  @mouseenter="handleMouseEnter(povs)"
                  @mouseleave="handleMouseLeave"
                >
                  <div>
                    <v-btn
                      outlined
                      text
                      rounded
                      dense
                      @click="CheckCardItemMethod(povs)"
                      class="text-capitalize pov-card-btn"
                      >View</v-btn
                    >
                  </div>
                  <div class="text-center pt-5">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          v-on="on"
                          v-bind="attrs"
                          class="pt-6 text-uppercase"
                          style="font-weight: 700"
                        >
                          {{
                            povs.pov_name.length < 10
                              ? povs.pov_name
                              : `${povs.pov_name.slice(0, 20)}...`
                          }}
                        </div>
                      </template>
                      <span>{{ povs.pov_name }}</span>
                    </v-tooltip>
                  </div>

                  <div class="text-center pt-5">
                    Day: {{ povs.pov_duration_day }}
                    <!-- {{ new Date(povs.pov_created_on * 1000).toLocaleString() }} -->
                  </div>
                  <div class="card-img">
                    <v-img src="@/assets/POVS/1.png" />
                  </div>
                  <div
                    class="additional-buttons"
                    v-if="isHovered(povs)"
                    @click.stop
                  >
                    <v-btn
                      color="#33bbc5"
                      class="hover-pov-btn"
                      @click="CheckItem(povs, 'EDIT')"
                      ><v-icon color="white">mdi-pencil-outline</v-icon></v-btn
                    >
                    <v-btn
                      color="#e91e63"
                      class="hover-pov-btn"
                      @click="CheckItem(povs, 'DELETE')"
                      ><v-icon color="white">mdi-delete-circle</v-icon></v-btn
                    >
                  </div>
                </v-card>
              </div>
            </div>
          </v-card>
        </v-container>
      </section>
    </v-card>
  </v-app>
</template>

<script>
import LoadingBuffer from "@/components/Extras/LoadingBuffer.vue";
import SnackBar from "@/components/Extras/SnackBar.vue";
import CreatePov from "@/components/TravelogsList/Dialogs/CreatePov.vue";
import ViewPostOfPOVs from "@/components/TravelogsList/Cards/ViewPostOfPOVs.vue";
import { GetPOVByTravelogId } from "@/mixins/TravelogsList/GetPOVByTravelogId.js";
import DeletePovDialog from "@/components/TravelogsList/Dialogs/DeletePovDialog.vue";
import PublishTravelog from "@/components/TravelogsList/Dialogs/PublishTravelog.vue";

export default {
  props: {
    StoreObj: Object,
  },
  components: {
    SnackBar,
    LoadingBuffer,
    CreatePov,
    ViewPostOfPOVs,
    DeletePovDialog,
    PublishTravelog,
  },
  mixins: [GetPOVByTravelogId],
  data: () => ({
    SnackBarComponent: {},
    LoadingBufferAnimation: false,
    CardHeight: 0,
    SearchPov: "",
    current_view: "VIEW_POVS",
    PublishTravelogDialog: false,
    publishMessageDisp: "",
    povDialog: false,
    DeletePovDialog: false,
    StorePOVsObj: {},
    ListPOVsOfTravelogue: [],
    FilteredPOV: [],
    hoveredCards: [],
  }),
  watch: {
    SearchPov(val) {
      if (val && val !== "" && val !== null) {
        this.FilteredPOV = this.POVScreated.filter((item) => {
          return item.pov_name.toLowerCase().startsWith(val.toLowerCase());
        });
      }
    },
  },

  async mounted() {
    this.CardHeight = window.innerHeight - 200;
    await this.GetPOVByTravelogIdMethod(this.StoreObj.travelogue_id);
    await this.sortPOVsByDuration();
  },
  methods: {
    async CreatePovEmit(sortToggle) {
      this.povDialog = false;
      if (sortToggle == 2) {
        await this.GetPOVByTravelogIdMethod(this.StoreObj.travelogue_id);
        await this.sortPOVsByDuration();
      }
    },

    PublishTravelogMessage(publishMessage) {
      this.publishMessageDisp = publishMessage;
    },

    CheckCardItemMethod(item) {
      this.LoadingBufferAnimation = true;
      setTimeout(() => {
        this.StorePOVsObj = item;
        this.current_view = "VIEW_POSTS";
        this.LoadingBufferAnimation = false;
      }, 1000);
    },
    TravelogueCardEmit(Toggle) {
      this.$emit("clicked", Toggle);
    },
    ViewPostsOfPovsEmit() {
      this.current_view = "VIEW_POVS";
    },

    isHovered(povs) {
      return this.hoveredCards.includes(povs);
    },

    handleMouseEnter(povs) {
      this.hoveredCards.push(povs);
    },

    handleMouseLeave(povs) {
      const index = this.hoveredCards.indexOf(povs);
      if (index !== -1) {
        this.hoveredCards.splice(index, 1);
      }
    },

    CheckItem(item, action) {
      this.StorePOVsObj = item;
      this.StorePOVsObj.action = action;
      if (action === "EDIT") {
        this.povDialog = true;
      } else {
        this.DeletePovDialog = true;
      }
    },

    async sortPOVsByDuration() {
      await this.GetPOVByTravelogIdMethod(this.StoreObj.travelogue_id);
      if (this.POVScreated && this.POVScreated.length > 0) {
        this.POVScreated.sort((a, b) => {
          return a.pov_duration_day - b.pov_duration_day;
        });
      }
    },

    async delete_pov_dialog(Toggle) {
      this.DeletePovDialog = false;
      if (Toggle == 2) {
        await this.GetPOVByTravelogIdMethod(this.StoreObj.travelogue_id);
      }
    },

    CheckItemMethod() {
      // this.StoreObj = item;
      this.PublishTravelogDialog = true;
    },

    async PublishTravelogDialogEmit(Toggle) {
      if (Toggle == 1 || Toggle == 2) {
        this.PublishTravelogDialog = false;
      }
    },

    logout() {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "red",
        SnackbarText: "Logout Successfull",
      };
      setTimeout(() => {
        this.$store.commit("SetUserEmail", "");
        this.$store.commit("SetUserDetails", {});
        this.$router.push("/");
      }, 900);
    },
  },
};
</script>

<!-- <style scoped>
  #pov-app {
    font-family: "Poppins", sans-serif;
  }
  
  .logout-btn {
    background: linear-gradient(to bottom, #33bbc5, #e91e63);
    color: white;
  }
  
  .user-info-div {
    line-height: 0;
    position: relative;
    left: 73px;
  }
  
  .user-info-div p {
    font-size: 13px;
    font-weight: 500;
  }
  
  .pov-card2 {
    /* background: linear-gradient(to bottom, #33bbc5, #e91e63); */
    border-radius: 20px;
    max-height: 100%;
    overflow-y: auto;
    /* padding: 70px; */
  }
  
  .pov-card2-img {
    object-fit: contain;
  }
  
  .pov-card-btn {
    position: absolute;
    top: 5px;
    right: 5px;
  }
  
  .edit-btn-div {
    position: absolute;
    top: 25px;
    right: 25px;
  }
  
  .sec2-btn {
    color: black;
    background-color: white;
    font-size: 12px;
  }
  
  .sec2Card-p {
    color: white;
  }
  
  .pov-card3 {
    border-radius: 20px;
    background-color: #dfe6f0;
    padding: 70px;
  }
  
  .pov-cards-div {
    display: flex;
    flex-wrap: wrap;
  }
  
  .pov-search-field {
    width: 300px;
  }
  
  .additional-buttons {
    position: absolute;
    bottom: 0;
    right: 0;
    display: none;
  }
  
  .CustomizePovCard {
    transition: transform 0.3s ease;
  }
  
  .CustomizePovCard:hover {
    transform: scale(0.9);
  }
  .CustomizePovCard:hover .additional-buttons {
    display: flex;
    flex-direction: row;
  }
  
  .CustomizePovCard:hover .additional-buttons {
    display: flex;
    flex-direction: row;
  }
  
  .hover-pov-btn {
    margin: 5px;
    border-radius: 100px;
  }
  .hover-pov-btn:first-child {
    margin-left: auto;
  }
  .hover-pov-btn:last-child {
    margin-right: 10px;
    margin-bottom: 5px;
  }
  
  .card-img {
    position: absolute;
    bottom: 1px;
    object-fit: cover;
    overflow: hidden;
    width: 300px;
  }
  
  .opacity-pov-div {
    background-color: rgba(0, 0, 0, 0.5);
    margin: 40px;
    padding: 80px 20px 80px 20px;
    border-radius: 20px;
    align-items: center;
    position: relative;
    top: 17px;
  }
  </style>  -->
