<template>
  <section id="loading-buffer-section">
    <div v-if="LoadingBufferAnimation === true" class="loading-overlay">
      <v-overlay>
        <v-progress-circular indeterminate color="primary" size="100">
          <v-img :width="50" src="@/assets/travelog_logo_small.png"> </v-img>
        </v-progress-circular>
        <p class="buffering-content">Loading please wait...</p>
      </v-overlay>
    </div>
  </section>
</template>




<script>
export default {
  props: {
    LoadingBufferAnimation: Boolean,
  },
};
</script>
