import { list_pov } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";

export const GetPOVByTravelogId = {
  data: () => ({
    POVScreated: [],
    listPovArrayLen: undefined,
    noPovMessage: "",
  }),
  methods: {
    async GetPOVByTravelogIdMethod(travelogue_id) {
      try {
        let result = await API.graphql(
          graphqlOperation(list_pov, {
            input: {
              travelogue_id,
            },
          })
        );
        let results = JSON.parse(result.data.list_pov);
        let resultObj = JSON.parse(result.data.list_pov).Data;
        
        this.POVScreated = resultObj;
        if (results.Status === "SUCCESS") {
          this.listPovArrayLen = resultObj.length;
        } else {
          this.listPovArrayLen = 0;
          this.noPovMessage = results.Message;
        }
      } catch (error) {
        console.log("Error", error);
        this.noDataText = error.errors[0].message;
      }
    },
  },
};

