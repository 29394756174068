<template>
  <v-app>
    <LoadingBuffer :LoadingBufferAnimation="LoadingBufferAnimation" />
    <v-main color="transparent">
      <router-view />
    </v-main>
  </v-app>
</template>



<script>
/* eslint-disable */
import LoadingBuffer from "@/components/Extras/LoadingBuffer.vue";
import { GetCurrentUser } from "@/mixins/GetCurrentUser.js";
export default {
  mixins: [GetCurrentUser],
  components: {
    LoadingBuffer,
  },

  data() {
    return {
      LoadingBufferAnimation: false,
      userName: "",
      userEmail: "",
      SnackBarComponent: {},
      componentCheck: 0,
    };
  },
  mounted() {
    this.LoadingBufferAnimation = true;
    setTimeout(() => {
      this.LoadingBufferAnimation = false;
    }, 2500);
    this.GetCurrentUserMethod();
  },
};
</script>
