<template>
  <div>
    <SnackBar :SnackBarComponent="SnackBarComponent" />
    <v-dialog v-model="CreateEditTravelogueDialog" max-width="600px" persistent>
      <v-container fluid class="pa-0">
        <v-card elevation="0" class="pa-12 ma-0">
          <div class="create-dialog-head">
            <h1>
              {{
                StoreObj.action === "CREATE"
                  ? "Create Travelog"
                  : "Edit Travelog"
              }}
            </h1>
          </div>

          <div class="create-dialog-close-btn">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon @click="CreateEditTravelogueDialogEmit(1)" v-on="on"
                  ><v-icon>mdi-close-circle</v-icon></v-btn
                >
              </template>
              <span>Close</span>
            </v-tooltip>
          </div>

          <v-card-text>
            <v-form ref="form">
              <v-row no-gutters class="mt-4">
                <v-col cols="12">
                  <v-text-field
                    dense
                    outlined
                    v-model="travelName"
                    label="Name*"
                    :rules="[(v) => !!v || 'Required ']"
                    class="mr-2"
                    rounded
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    dense
                    v-model="travelDescription"
                    label="Description*"
                    :rules="[(v) => !!v || 'Required ']"
                    class="mr-2"
                    outlined
                    rounded
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-select
                    v-if="StoreObj.action == 'CREATE'"
                    v-model="departureCountry"
                    dense
                    :items="CountryList"
                    item-text="country_name"
                    :rules="[(v) => !!v || 'Required ']"
                    class="mr-2"
                    outlined
                    rounded
                  >
                    <template #label>
                      <span style="font-size: 12px">Departure Country*</span>
                    </template></v-select
                  >
                </v-col>
                <v-col cols="6">
                  <v-select
                    v-if="StoreObj.action == 'CREATE'"
                    v-model="departureState"
                    dense
                    :items="StateList"
                    item-text="state_name"
                    label="Departure State*"
                    :rules="[(v) => !!v || 'Required ']"
                    class="mr-2"
                    outlined
                    rounded
                  >
                    <template #label>
                      <span style="font-size: 12px">Departure State*</span>
                    </template>
                  </v-select>
                </v-col>
                <!-- <v-col cols="12">
                  <v-text-field
                    dense
                    outlined
                    v-model="travelPrice"
                    label="Price*"
                    :rules="[(v) => !!v || 'Required ']"
                    class="mr-2"
                    rounded
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    dense
                    outlined
                    v-model="travelInclusion"
                    label="Inclusion*"
                    :rules="[(v) => !!v || 'Required ']"
                    class="mr-2"
                    rounded
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    dense
                    outlined
                    v-model="travelExclusion"
                    label="Exclusion*"
                    :rules="[(v) => !!v || 'Required ']"
                    class="mr-2"
                    rounded
                  ></v-text-field>
                </v-col> -->
                <!-- <v-col cols="12" align="center" v-if="imageUrl === ''">
                  <input
                    id="uploadPic"
                    type="image"
                    hidden
                    @change="handleFileInput"
                  />
                  <p>Click here to upload cover picture</p>
                  <label for="uploadPic">
                    <v-card
                      flat
                      outlined
                      class="text-center pt-13"
                      color="grey"
                      height="150px"
                      max-width="150px"
                    >
                      <v-btn
                        text
                        class="white--text"
                        @click="SelectImageMethod()"
                      >
                        Choose File
                      </v-btn>
                    </v-card>
                  </label>
                </v-col> -->
                <!-- <v-col cols="12" align="center" v-else>
                  <v-card
                    flat
                    outlined
                    class="pt-3"
                    height="150px"
                    max-width="150px"
                  >
                    <v-btn
                      icon
                      fab
                      top
                      right
                      class="text-right mr-n8 mt-2"
                      absolute
                      @click="(imageUrl = ''), (has_image_selected = false)"
                      ><v-icon>mdi-close-thick</v-icon></v-btn
                    >
                    <v-img :src="imageUrl" height="150px" max-width="150px" />
                  </v-card>
                </v-col> -->
                <v-col cols="12" lg="12" sm="12" xs="12" md="3">
                  <v-file-input
                    v-show="false"
                    rounded
                    dense
                    outlined
                    ref="fileInput"
                    v-model="files"
                    @change="get_files_input(files)"
                  >
                  </v-file-input>
                  <v-card
                    elevation="0"
                    class="pt-4 pb-4"
                    style="border: 2px dashed grey"
                    @click="localImg.length === 0 && enableFileInput()"
                  >
                    <div
                      class="grid-class justify-center"
                      v-show="localImg.length === 0"
                    >
                      <p class="mt-1 pa-0">Click here to upload image</p>
                      <v-img
                        :width="40"
                        class="mx-auto"
                        src="@/assets/image-icon.png"
                      />
                    </div>
                    <div v-show="localImg.length !== 0">
                      <v-img
                        class="mx-auto"
                        :width="150"
                        :height="80"
                        :src="localImg"
                      />
                      <v-btn
                        @click.stop="removeLocalImg"
                        icon
                        class="deleteImgBtn"
                        ><v-icon color="red" size="15"
                          >mdi-close-circle</v-icon
                        ></v-btn
                      >
                    </div>
                    <v-img
                      v-if="showSuccessGif === true"
                      src="@/assets/upload-success-gif.gif"
                      :width="130"
                      class="mx-auto mt-3"
                    />
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              block
              @click="ValidateMethod()"
              :loading="loading"
              class="text-capitalize dialog-create-btn mt-3 white--text"
              depressed
              rounded
              >{{ StoreObj.action === "EDIT" ? "Update" : "Create" }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-container>
    </v-dialog>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { GenerateS3URL } from "@/mixins/Extras/GetPreSignedURL.js";
import SnackBar from "@/components/Extras/SnackBar.vue";
import {
  agent_travelogue_creation,
  update_travelogue_details,
} from "@/graphql/mutations.js";
import CountryListJson from "@/Json/countries.json";
import { API, graphqlOperation } from "aws-amplify";
export default {
  props: {
    CreateEditTravelogueDialog: Boolean,
    StoreObj: Object,
  },
  components: {
    SnackBar,
  },
  mixins: [GenerateS3URL],
  data: () => ({
    generateTravelogId: "",
    travelName: "",
    travelDescription: "",
    departureCountry: "",
    departureState: "",
    travelPrice: "",
    travelInclusion: "",
    travelExclusion: "",
    imageUrl: "",
    s3ImgUrl: "",
    files: "",
    localImg: "",
    profile_pic_url: "",
    CountryList: [],
    StateList: [],
    picDisp: "",
    uploadedImage: false,
    loading: false,
    has_image_selected: false,
    SnackBarComponent: {},
    selectedImage: null,
    showSuccessGif: false,
  }),

  watch: {
    CreateEditTravelogueDialog(val) {
      if (val === true) {
        CountryListJson.map((item) => {
          this.CountryList.push({ country_name: item.name });
        });
        if (this.StoreObj.action === "EDIT") {
          this.travelName = this.StoreObj.travelogue_name;
          this.travelDescription = this.StoreObj.travelogue_description;
          this.imageUrl = this.StoreObj.travelogue_image;
        }
      }
    },

    departureCountry(val) {
      this.StateList = [];
      CountryListJson.map((item) => {
        if (item.name === val) {
          item.states.map((state) => {
            this.StateList.push({ state_name: state.name });
          });
        }
      });
    },
  },

  methods: {
    async Preview_image() {
      this.generateTravelogId = uuidv4();
    },

    enableFileInput() {
      this.$refs.fileInput.$el.querySelector("input").click();
    },

    async ValidateMethod() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (
          this.StoreObj.action === "CREATE" ||
          this.StoreObj.action === "EDIT"
        ) {
          this.generateTravelogId = uuidv4();
          let result = await this.GenerateS3URLMethods(
            `POST_UPLOAD/${this.generateTravelogId}/${this.files.name}`,
            this.files
          );
          if (result.status === "SUCCESS") {
            this.create_master_locations_travelogList(result.file_URL);
          } else {
            this.create_master_locations_travelogList();
          }
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: "Please fill mandatory fields",
        };
      }
    },

    async create_master_locations_travelogList(url) {
      let mutation_name_travelogList =
        this.StoreObj.action === "CREATE"
          ? agent_travelogue_creation
          : update_travelogue_details;
      let mutation_result_travelogList =
        this.StoreObj.action === "CREATE"
          ? "agent_travelogue_creation"
          : "update_travelogue_details";
      try {
        let result = await API.graphql(
          graphqlOperation(mutation_name_travelogList, {
            input: {
              travelogue_id:
                this.StoreObj.action === "CREATE"
                  ? this.generateTravelogId
                  : this.StoreObj.travelogue_id,
              travelogue_name: this.travelName,
              travelogue_description: this.travelDescription,
              travelogue_Departure_country:
                this.StoreObj.action === "CREATE"
                  ? this.departureCountry
                  : undefined,
              travelogue_Departure_state:
                this.StoreObj.action === "CREATE"
                  ? this.departureState
                  : undefined,
              // price:
              //   this.StoreObj.action === "CREATE"
              //     ? this.travelPrice
              //     : this.StoreObj.travelogue_id,
              // inclusions:
              //   this.StoreObj.action === "CREATE"
              //     ? this.travelInclusion
              //     : this.StoreObj.travelogue_id,
              // exclusions:
              //   this.StoreObj.action === "CREATE"
              //     ? this.travelExclusion
              //     : this.StoreObj.travelogue_id,

              country_code:
                this.StoreObj.action === "CREATE"
                  ? this.$store.getters.GetUserDetails.country_code
                  : undefined,
              agent_phone_number:
                this.StoreObj.action === "CREATE"
                  ? this.$store.getters.GetUserDetails.agent_phone_number
                  : undefined,
              travelogue_image: url,
            },
          })
        );
        var response = JSON.parse(result.data[mutation_result_travelogList]);
        if (response.Status == "SUCCESS") {
          this.showSuccessGif = true;
          setTimeout(() => {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              SnackbarText: response.Message,
            };
            this.loading = false;
            this.CreateEditTravelogueDialogEmit(2);
          }, 3500);

        }
      } catch (error) {
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: error.errors[0].message,
        };
      }
    },

    get_files_input(files) {
      this.localImg = URL.createObjectURL(files);
    },

    removeLocalImg() {
      this.files = "";
      this.localImg = "";
    },

    checkImagePresent() {
      if (this.imgSrc || this.imgSrc == undefined || this.imgSrc == "") {
        this.$refs["image-upload-input"].click();
      }
    },
    GetImageUrlMethod(existingUrl) {
      if (this.s3ImgUrl === existingUrl) {
        return this.StoreObj.travelogue_image;
      } else {
        return this.s3ImgUrl;
      }
    },

    handleClick(event) {
      this.imgSrc = event.target.files[0];
      this.GenerateS3URLMethods(`POST_UPLOAD`, this.imgSrc);
    },
    CreateEditTravelogueDialogEmit(Toggle) {
      this.files = "";
      this.localImg = "";
      this.showSuccessGif = false;
      this.$refs.form.reset();
      this.$emit("clicked", Toggle);
    },
  },
};
</script>

<style scoped>
.grid-class {
  display: grid;
}

.deleteImgBtn {
  position: absolute;
  top: -3px;
  right: 125px;
}
</style>

<!-- <style scoped>
.create-dialog-close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
}

.create-dialog-head {
  position: absolute;
  top: 20px;
  left: 20px;
}

.dialog-create-btn {
  background: linear-gradient(to bottom, #33bbc5, #e91e63);
  color: white;
}
</style> -->
