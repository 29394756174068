import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

/* eslint-disable no-new */
const store = new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    pAuth: false,
    pUserEmail: "",
    pCurrentUserDetails: {},
    pTravelDetails: {},
  },
  getters: {
    GetAuth: (state) => {
      return state.pAuth || false;
    },

    GetUserEmail: (state) => {
      return state.pUserEmail;
    },
    GetUserDetails: (state) => {
      return state.pCurrentUserDetails;
    },
    GetTravelDetails: (state) => {
      return state.pTravelDetails;
    },
  },
  mutations: {
    SetAuth(state, status) {
      state.pAuth = status;
    },

    SetUserEmail(state, useremail) {
      state.pUserEmail = useremail;
    },
    SetUserDetails(state, userDetails) {
      state.pCurrentUserDetails = userDetails;
    },
    SetTravelDetails(state, travelDetails) {
      state.pTravelDetails = travelDetails;
    },
  },
  actions: {
    removeAuth(context) {
      context.commit("SetUserEmail", "");
      context.commit("SET_AUTH", false);
    },
  },
  modules: {},
});

export default store;
