import { list_post } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
// import { PreSignedURL } from "@/mixins/Extras/GetPreSignedURL.js";

export const GetPostsByPOVS = {
  // mixins: [PreSignedURL],
  methods: {
    async GGetPostsByPOVSMethod(pov_id) {
      try {
        let result = await API.graphql(
          graphqlOperation(list_post, {
            input: {
              pov_id,
            },
          })
        );
        return JSON.parse(result.data.list_post).Data;
      } catch (error) {
        console.log("Error", error);
      }
    },
  },
};
