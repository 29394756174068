<template>
  <div>
    <SnackBar :SnackBarComponent="SnackBarComponent" />
    <v-dialog
      :width="500"
      v-model="AddPostToPovDialog"
      persistent
      max-width="500px"
    >
      <v-container fluid class="pa-0">
        <v-card elevation="0" class="pa-12 ma-0">
          <div class="upload-heading pa-0">
            <h1>Upload Post</h1>
          </div>
          <div class="upload-dialog-close-btn">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon @click="AddPostToPovDialogEmit(1)" v-on="on"
                  ><v-icon>mdi-close-circle</v-icon></v-btn
                >
              </template>
              <span>Close</span>
            </v-tooltip>
          </div>
          <v-card-text class="mt-3">
            <v-file-input
              class="mt-5"
              outlined
              dense
              multiple
              label="File input"
              width="300px"
              accept="image/*"
              v-model="allFiles"
              @change="get_files_input(allFiles)"
              rounded
            ></v-file-input>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              block
              color="black"
              :loading="loading1"
              @click="upload_images()"
              class="text-capitalize upload-btn"
              rounded
              >Upload</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-container>
    </v-dialog>
  </div>
</template>

<script>
import { post_creation } from "@/graphql/mutations.js";
import SnackBar from "@/components/Extras/SnackBar.vue";
import { API, graphqlOperation } from "aws-amplify";
import { GenerateS3URL } from "@/mixins/Extras/GetPreSignedURL.js";
const AWS = require("aws-sdk");
export default {
  components: {
    SnackBar,
  },

  props: {
    AddPostToPovDialog: Boolean,
    StoreObj: Object,
    StorePOVsObj: Object,
  },
  mixins: [GenerateS3URL],
  data: () => ({
    loading1: false,
    allFiles: [],
    selectedFiles: [],
    SnackBarComponent: {},
    uploadedUrls: [],
    IsDialogGetClose: false,
  }),
  methods: {
    async upload_images() {
      this.loading1 = true;
      for (let i = 0; i < this.allFiles.length; i++) {
        let result = await this.GenerateS3URLMethods(
          `POST_UPLOAD/${this.StoreObj.travelogue_id}/${
            this.StorePOVsObj.pov_id
          }/${this.$store.getters.GetUserDetails.agent_id}/${Date.now()}.${
            this.allFiles[i].name.split(".")[1]
          }`,
          this.allFiles[i]
        );
        if (result.status === "SUCCESS") {
          this.update_post_mutations(result.file_URL);
          if (i === this.allFiles.length - 1) {
            this.IsDialogGetClose = true;
          }
        }
      }
    },
    // constructS3Url(uploadResponse) {
    //   var data = this.$store.getters.GetUserDetails.s3Details;
    //   const { Bucket, Key } = uploadResponse;
    //   const region = data.region;
    //   return `https://${Bucket}.s3.${region}.amazonaws.com/${Key}`;
    // },

    async update_post_mutations(url) {
      // this.uploadURLData.push(url);

      try {
        this.loading1 = true;
        let result = await API.graphql(
          graphqlOperation(post_creation, {
            input: {
              phone_number:
                this.$store.getters.GetUserDetails.agent_phone_number,
              travelogue_id: this.StoreObj.travelogue_id,
              pov_id: this.StorePOVsObj.pov_id,
              post_type: "IMAGE",
              post_url: url,
            },
          })
        );
        var response = JSON.parse(result.data.post_creation);
        if (response.Status == "SUCCESS") {

          if (this.IsDialogGetClose === true) {
            this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            SnackbarText: response.Message,
          };
            this.loading1 = false;
            this.AddPostToPovDialogEmit(2);
          }
        } else {
          this.allFiles = [];
        }
      } catch (error) {
        this.loading = false;
      }
    },
    
    get_files_input(files) {
      this.uploadedUrls = "";
      this.uploadURLData = [];
      this.selectedFiles = files;
    },
    AddPostToPovDialogEmit(Toggle) {
      this.IsDialogGetClose = false;
      this.allFiles = [];
      this.$emit("clicked", Toggle);
    },
  },
};
</script>

<style scoped>
.upload-heading {
  position: absolute;
  top: 20px;
  left: 20px;
}
.upload-btn {
  background: linear-gradient(to bottom, #33bbc5, #e91e63);
  color: white;
}

.upload-dialog-close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
}
</style>
