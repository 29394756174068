/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const agent_travelogue_creation = /* GraphQL */ `
  mutation Agent_travelogue_creation($input: CreateTravelogueInput) {
    agent_travelogue_creation(input: $input)
  }
`;
export const publish_travelogue = /* GraphQL */ `
  mutation Publish_travelogue($input: PublishTravelogueInput) {
    publish_travelogue(input: $input)
  }
`;
export const update_travelogue_details = /* GraphQL */ `
  mutation Update_travelogue_details($input: UpdateTravelogueInput) {
    update_travelogue_details(input: $input)
  }
`;
export const delete_travelogue_status = /* GraphQL */ `
  mutation Delete_travelogue_status($input: DeleteTravelogueInput) {
    delete_travelogue_status(input: $input)
  }
`;
export const pov_creation = /* GraphQL */ `
  mutation Pov_creation($input: CreatePovInput) {
    pov_creation(input: $input)
  }
`;
export const edit_pov = /* GraphQL */ `
  mutation Edit_pov($input: EditPovInput) {
    edit_pov(input: $input)
  }
`;
export const delete_pov = /* GraphQL */ `
  mutation Delete_pov($input: DeletePovInput) {
    delete_pov(input: $input)
  }
`;
export const post_creation = /* GraphQL */ `
  mutation Post_creation($input: CreatePostInput) {
    post_creation(input: $input)
  }
`;
export const delete_post = /* GraphQL */ `
  mutation Delete_post($input: DeletePostInput) {
    delete_post(input: $input)
  }
`;
