<template>
  <div :class="{ 'transparent-background': MaximizePovPost }">
    <v-dialog v-model="MaximizePovPost" max-width="1200px">
      <v-container class="pa-0" fluid>
        <v-card class="pa-2" elevation="0" color="black">
          <v-img contain max-width="1200px" max-height="600px" :src="povPost" />
          <div class="viewPostBtn">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  class="pl-5"
                  icon
                  color="white"
                  @click="ClosePovPostDialog(1)"
                  v-on="on"
                  ><v-icon>mdi-close-circle</v-icon></v-btn
                >
              </template>
              <span>Close</span>
            </v-tooltip>
          </div>
          <div align="center" class="mt-1">
            <v-btn
              :disabled="currentImgIndex === 0"
              icon
              color="white"
              class="mr-2"
              @click="navigateBack()"
            >
              <v-icon>mdi-arrow-left-circle</v-icon>
            </v-btn>
            <span class="white--text"
              >{{ currentImgIndex + 1 }}/{{ maximizePovPostList.length }}</span
            >
            <v-btn
              :disabled="currentImgIndex === maximizePovPostList.length - 1"
              icon
              color="white"
              class="ml-2"
              @click="navigateForward()"
            >
              <v-icon>mdi-arrow-right-circle</v-icon>
            </v-btn>
          </div>
        </v-card>
      </v-container>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    MaximizePovPost: Boolean,
    currentImgIndex: Number,
    maximizePovPostList: Array,
  },
  data() {
    return {
      povPost: "",
    };
  },

  watch: {
    MaximizePovPost(val) {
      if (val) {
        this.povPost = this.maximizePovPostList[this.currentImgIndex];
      }
    },
  },

  methods: {
    navigateForward() {
      this.currentImgIndex += 1;
      this.povPost = this.maximizePovPostList[this.currentImgIndex];
    },

    navigateBack() {
      if (this.currentImgIndex === 0) {
        return;
      }

      this.currentImgIndex = this.currentImgIndex - 1;
      this.povPost = this.maximizePovPostList[this.currentImgIndex];
    },

    ClosePovPostDialog(Toggle) {
      this.$emit("clicked", Toggle);
    },
  },
};
</script>

<style scoped>
.viewPostBtn {
  position: absolute;
  top: 4px;
  right: 14px;
}
.v-img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.transparent-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}
</style>

<!-- <template>
  <div :class="{ 'transparent-background': MaximizePovPost }">
    <v-dialog v-model="MaximizePovPost" max-width="1200px" persistent>
      <v-container class="pa-0" fluid>
        <v-card class="pa-2" elevation="0" color="black">
          <v-img
            contain
            max-width="1200px"
            max-height="600px"
            :src="selectedPost.post_url"
          />
          <div class="viewPostBtn">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  class="delete-dialog-close-btn"
                  icon
                  color="white"
                  @click="ClosePovPostDialog(1)"
                  v-on="on"
                  ><v-icon>mdi-close</v-icon></v-btn
                >
              </template>
              <span>Close</span>
            </v-tooltip>
          </div>
          <div align="center" class="mt-1">
            <v-btn icon color="white" class="mr-2" @click="navigate(-1)">
              <v-icon>mdi-arrow-left-circle</v-icon>
            </v-btn>
            <v-btn icon color="white" class="ml-2" @click="navigate(1)">
              <v-icon>mdi-arrow-right-circle</v-icon>
            </v-btn>
          </div>
        </v-card>
      </v-container>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    MaximizePovPost: Boolean,
    selectedPost: Object,
    posts: Array,
  },
  data() {
    return {
      currentIndex: 0,
    };
  },

  methods: {
    ClosePovPostDialog(Toggle) {
      this.currentIndex = 0
      this.$emit("clicked", Toggle);
    },

    navigate(direction) {
      const newIndex = this.currentIndex + direction;

      if (newIndex >= 0 && newIndex < this.posts.length) {
        this.currentIndex = newIndex;
        this.selectedPost = this.posts[newIndex];
      }
    },

    ClosePovPostDialog(Toggle) {
      this.$emit("clicked", Toggle);
    },
  },
};
</script>

<style scoped>
.viewPostBtn {
  position: absolute;
  top: 4px;
  right: 14px;
}
.v-img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.transparent-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.7
  );
  z-index: 999;
}
</style> -->
