import Vue from 'vue'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify'
import router from './router'
import { Amplify } from "aws-amplify"
import * as VueGoogleMaps from "vue2-google-maps";

Vue.config.productionTip = false
Amplify.configure({
  Auth: {
    userPoolId: "ap-south-1_sgESCOmoQ",
    region: "ap-south-1",
    userPoolWebClientId: "4mjcnt3chvka5phvpmvi0phghi",
  },

  API: {
    aws_project_region: "ap-south-1",
    aws_appsync_graphqlEndpoint: "https://mrfcd4uvpfaxfdflvpjwp7otp4.appsync-api.ap-south-1.amazonaws.com/graphql",
    aws_appsync_region: "ap-south-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"

  },
})
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBUp8E7OghfEFg9fJ1ePokoOhE1I8MaD-M",
    libraries: "places" // necessary for places input
  }
});

new Vue({
  store,
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
