import { list_my_draft_travelogues } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";

export const get_travelogs = {
  methods: {
    async get_travelogs_method() {
      try {
        let result = await API.graphql(
          graphqlOperation(list_my_draft_travelogues, {
            input: {
              phone_number:
                this.$store.getters.GetUserDetails.agent_phone_number,
            },
          })
        );
        return JSON.parse(result.data.list_my_draft_travelogues).Data;
      } catch (error) {
        console.log("Error", error);
        this.noDataText = error.errors[0].message;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: error.errors[0].message,
          timeout: 5000,
          Top: true,
        };
      }
    },
  },
};
