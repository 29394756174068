import { list_approved_agent_travelogues } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";

export const GetApprovedTravelogue = {
  methods: {
    async GetApprovedTravelogueMethods() {
      try {
        let result = await API.graphql(
          graphqlOperation(list_approved_agent_travelogues, {
            input: {
              phone_number:
                this.$store.getters.GetUserDetails.agent_phone_number,
            },
          })
        );
        return JSON.parse(result.data.list_approved_agent_travelogues).Data;
      } catch (error) {
        console.log("Error", error);
        this.noDataText = error.errors[0].message;
      }
    },
  },
};
