<template>
  <v-app id="travelog-page">
    <SnackBar :SnackBarComponent="SnackBarComponent" />
    <LoadingBuffer :LoadingBufferAnimation="LoadingBufferAnimation" />
    <CreateTravelogue
      :CreateEditTravelogueDialog="CreateEditTravelogueDialog"
      :StoreObj="StoreObj"
      @clicked="CreateEditTravelogueDialogEmit"
    />
    <DeleteTravelogDialog
      :DeleteTravelogDialog="DeleteTravelogDialog"
      :StoreObj="StoreObj"
      @clicked="delete_travelog_dialog"
    />
    <ViewPovDetailsOfTravelogue
      v-if="current_view == 'VIEW_POVS_LIST'"
      :StoreObj="StoreObj"
      @clicked="TravelogueCardEmit"
    />

    <section id="travelog-sec1" v-if="current_view == 'VIEW_TRAVELOG_LIST'">
      <v-container fluid class="pa-0">
        <v-img
          class="mx-auto"
          :width="1536"
          :height="400"
          src="@/assets/mountain-img.jpg"
        >
          <v-row class="mt-5 ml-16 mr-16 travelog-sec1-row">
            <v-col cols="6" lg="6" md="6" sm="6" xs="12">
              <v-img :width="195" src="@/assets/new-trvlg-logo-light.png" />
              <div class="user-info-div">
                <p color="white">
                  {{ $store.getters.GetUserDetails.agent_name }}
                </p>
                <p color="white">
                  {{ $store.getters.GetUserDetails.agent_email }}
                </p>
              </div>
            </v-col>

            <v-col cols="6" lg="6" md="6" sm="6" xs="12" class="text-end">
              <v-btn
                rounded
                class="text-capitalize white--text mr-7 logout-btn-travelogPage"
                @click="logout()"
                >Logout</v-btn
              >
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon>
                    <v-icon v-on="on" color="white" size="50"
                      >mdi-account-circle</v-icon
                    ></v-btn
                  >
                </template>
                <span class="pa-0">
                  <p>Name: {{ $store.getters.GetUserDetails.agent_name }}</p>
                  <p>Email: {{ $store.getters.GetUserDetails.agent_email }}</p>
                </span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-img>
      </v-container>
    </section>

    <section id="travelog-sec2" v-if="current_view == 'VIEW_TRAVELOG_LIST'">
      <v-container fluid class="pa-0">
        <v-card
          elevation="0"
          max-width="1536"
          class="travelog-sec2-card mx-auto pl-16 pr-16"
        >
          <v-card-text v-if="current_view == 'VIEW_TRAVELOG_LIST'">
            <v-row row>
              <v-col
                cols="12"
                lg="5"
                md="5"
                sm="12"
                xs="12"
                class="travelog-sec2-cols"
              >
                <p class="display-2 font-weight-regular">My Travelog</p>
              </v-col>
              <v-col
                cols="12"
                lg="7"
                md="7"
                sm="12"
                xs="12"
                class="text-end travelog-sec2-cols"
              >
                <div class="travelog-col2">
                  <div>
                    <v-text-field
                      v-model="SearchBy"
                      outlined
                      rounded
                      dense
                      append-icon="mdi-magnify"
                      class="text-capitalize travelog-inp-field"
                    >
                      <template #label>
                        <span style="font-size: 12px"
                          >Search For Travelog Name</span
                        >
                      </template>
                    </v-text-field>
                  </div>

                  <div>
                    <v-select
                      rounded
                      outlined
                      dense
                      :items="ListFilterItems"
                      class="travelog-inp-field"
                      v-model="FilterBy"
                    >
                      <template #label>
                        <span style="font-size: 12px">Travelog Status</span>
                      </template>
                    </v-select>
                  </div>

                  <div class="create-travelog-div">
                    <v-btn
                      elevation="0"
                      :height="40"
                      color="#2222BB"
                      rounded
                      class="text-capitalize white--text mr-7 crt-trvlg-btn"
                      @click="
                        (CreateEditTravelogueDialog = true),
                          (StoreObj.action = 'CREATE')
                      "
                    >
                      Create Travelog
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-data-table
              :headers="
                FilterBy != 'Rejected'
                  ? headers.filter(
                      (item) => item.text != 'Reason For Rejection'
                    )
                  : FilterBy === 'Pending For Approvals'
                  ? headers.filter((item) => item.text != 'Publish')
                  : headers
              "
              :items="SearchBy == '' ? travelogsArray : FilteredTravelogue"
              :items-per-page="8"
              @click:row="CheckRowItem"
              :page.sync="page"
              @page-count="PageCount = $event"
              hide-default-footer
              class="mt-10"
            >
              <template v-slot:[`item.travelogue_description`]="{ item }">
                <div>
                  <v-tooltip bottom max-width="950px">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on"
                        >{{ formateDescription(item.travelogue_description) }}
                      </span>
                    </template>
                    <span>{{ item.travelogue_description }}</span>
                  </v-tooltip>

                  <!-- {{ formateDescription(item.travelogue_description) }} -->
                </div>
              </template>
              <template v-slot:[`item.travelogue_created_on`]="{ item }">
                <div>
                  {{
                    new Date(item.travelogue_created_on * 1000).toLocaleString()
                  }}
                </div>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  icon
                  small
                  class="mr-2"
                  @click.stop="checkItem(item, 'EDIT')"
                >
                  <v-icon small color="green">mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon small @click.stop="checkItem(item, 'DELETE')">
                  <v-icon small color="red">mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>
            <v-pagination
              dense
              circle
              v-model="page"
              :length="PageCount"
              prev-icon="mdi-chevron-left"
              next-icon="mdi-chevron-right"
              color="#2222BB"
              class="mx-auto"
            ></v-pagination>
          </v-card-text>
        </v-card>
      </v-container>
    </section>
  </v-app>
</template>

<script>
import { VTooltip } from "v-tooltip";
import SnackBar from "@/components/Extras/SnackBar.vue";
import LoadingBuffer from "@/components/Extras/LoadingBuffer.vue";
import CreateTravelogue from "@/components/TravelogsList/Dialogs/CreateTravelogs.vue";
import DeleteTravelogDialog from "@/components/TravelogsList/Dialogs/DeleteTravelogDialog.vue";
import ViewPovDetailsOfTravelogue from "@/components/TravelogsList/Cards/ViewPovDetailsOfTravelogue.vue";
import { get_travelogs } from "@/mixins/TravelogsList/GetTravelogs.js";
import { GetPendingTravelogue } from "@/mixins/TravelogsList/GetListPendingTravelogues.js";
import { GetRejectedTravelogue } from "@/mixins/TravelogsList/GetListRejectedTravelogues.js";
import { GetApprovedTravelogue } from "@/mixins/TravelogsList/GetListApprovedTravelog.js";
export default {
  components: {
    SnackBar,
    LoadingBuffer,
    CreateTravelogue,
    ViewPovDetailsOfTravelogue,
    DeleteTravelogDialog,
  },
  mixins: [
    get_travelogs,
    GetPendingTravelogue,
    GetRejectedTravelogue,
    GetApprovedTravelogue,
  ],
  data: () => ({
    LoadingBufferAnimation: false,
    page: 1,
    PageCount: 1,
    FilterBy: "",
    current_view: "VIEW_TRAVELOG_LIST",
    CreateEditTravelogueDialog: false,
    PublishTravelogDialog: false,
    StoreObj: {},
    SearchBy: "",
    DeleteTravelogDialog: false,
    ListFilterItems: ["Approved", "Draft", "Pending For Approvals", "Rejected"],
    travelogsArray: [],
    FilteredTravelogue: [],
    headers: [
      { text: "Name", value: "travelogue_name", width: 200, sortable: false },
      { text: "Description", value: "travelogue_description", sortable: false },
      {
        text: "Reason For Rejection",
        value: "rejection_reason",
        sortable: false,
      },
      { text: "Created On", value: "travelogue_created_on", sortable: false },
      { text: "Edit/Delete", value: "actions", sortable: false },
    ],
    SnackBarComponent: {},
  }),
  async mounted() {
    this.FilterBy = "Draft";
    this.travelogsArray = await this.get_travelogs_method();

  },
  watch: {
    SearchBy(val) {
      if (val && val !== "" && val !== null) {
        this.FilteredTravelogue = this.travelogsArray.filter((item) => {
          return item.travelogue_name
            .toLowerCase()
            .startsWith(val.toLowerCase());
        });
      }
    },
    async FilterBy(val) {
      if (val === "Pending For Approvals") {
        this.travelogsArray = await this.GetPendingTravelogueMethods();
      } else if (val === "Rejected") {
        this.travelogsArray = await this.GetRejectedTravelogueMethods();
      } else if (val === "Draft") {
        this.travelogsArray = await this.get_travelogs_method();
      } else if (val === "Approved") {
        this.travelogsArray = await this.GetApprovedTravelogueMethods();
      }
    },
  },
  methods: {
    formateDescription(item) {
      if (item.length > 100) {
        return `${item.slice(0, 100)} ...`;
      } else {
        return item;
      }
    },

    CheckRowItem(item) {
      if (this.FilterBy != "Pending For Approvals") {
        this.LoadingBufferAnimation = true;
        setTimeout(() => {
          if (this.FilterBy != "Pending For Approvals") {
            this.StoreObj = item;
            this.current_view = "VIEW_POVS_LIST";
            this.LoadingBufferAnimation = false;
          } else {
            this.LoadingBufferAnimation = false;
          }
        }, 2500);
      } else {
        this.loadingBuffer = false;
      }
    },
    TravelogueCardEmit(Toggle) {
      if (Toggle === 1) {
        this.current_view = "VIEW_TRAVELOG_LIST";
      }
    },

    checkItem(item, action) {
      this.StoreObj = item;
      this.StoreObj.action = action;
      if (action === "EDIT") {
        this.CreateEditTravelogueDialog = true;
      } else {
        this.DeleteTravelogDialog = true;
      }
    },

    async delete_travelog_dialog(Toggle) {
      this.DeleteTravelogDialog = false;
      if (Toggle === 2) {
        if (this.FilterBy === "Draft") {
          this.travelogsArray = await this.get_travelogs_method();
        } else if (this.FilterBy === "Pending For Approvals") {
          this.travelogsArray = await this.GetPendingTravelogueMethods();
        } else if (this.FilterBy === "Approved") {
          this.travelogsArray = await this.GetApprovedTravelogueMethods();
        } else {
          this.travelogsArray = await this.GetRejectedTravelogueMethods();
        }
      }
    },

    async CreateEditTravelogueDialogEmit(Toggle) {
      this.CreateEditTravelogueDialog = false;
      if (Toggle == 2) {
        if (this.FilterBy === "Draft") {
          this.travelogsArray = await this.get_travelogs_method();
        } else if (this.FilterBy === "Pending For Approvals") {
          this.travelogsArray = await this.GetPendingTravelogueMethods();
        } else if (this.FilterBy === "Approved") {
          this.travelogsArray = await this.GetApprovedTravelogueMethods();
        } else {
          this.travelogsArray = await this.GetRejectedTravelogueMethods();
        }
      }
    },

    logout() {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "red",
        SnackbarText: "Logout Successfull",
      };
      setTimeout(() => {
        this.$store.commit("SetUserEmail", "");
        this.$store.commit("SetUserDetails", {});
        this.$router.push("/");
      }, 900);
    },
  },
};
</script>

<!-- <style scoped>
#travelog-page {
  font-family: "Raleway", sans-serif;
}

.travelog-sec1-row {
  align-items: center;
}

.logout-btn {
  background: linear-gradient(to bottom, #33bbc5, #e91e63);
  color: white;
}

.user-info-div {
  line-height: 0;
  position: relative;
  left: 73px;
}

.user-info-div p {
  font-size: 13px;
  color: white;
}

#travelog-sec2 {
  margin-top: 30px;
}

.crt-trvlg-btn {
  color: white;
  position: relative;
  top: 25px;
}

.travelog-inp-field {
  width: 250px;
}

.travelog-col2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.create-travelog-div {
  position: relative;
  bottom: 26px;
}
</style> -->
