<template>
  <!-- eslint-disable -->
  <div>
    <SnackBar :SnackBarComponent="SnackBarComponent" />
    <v-dialog :width="500" v-model="povDialog" persistent max-width="800">
      <v-container fluid class="pa-0">
        <v-card elevation="0" class="pa-15 ma-0">
          <div class="create-dialog-head">
            <h1>
              {{ StorePOVsObj.action === "CREATE" ? "Create Pov" : "Edit Pov" }}
            </h1>
          </div>

          <div class="createpov-dialog-close">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon @click="CreatePovEmit()" v-on="on"
                  ><v-icon class="ml-2">mdi-close-circle</v-icon></v-btn
                >
              </template>
              <span>Close</span>
            </v-tooltip>
          </div>

          <div class="createEdit-pov-fields">
            <div class="mt-5">
              <gmap-autocomplete
                class="gmap-input-field"
                placeholder="Search Location"
                :value="search"
                ref="clearSearch"
                @place_changed="setPlace"
              ></gmap-autocomplete>
              <v-btn icon small class="pa-0 ml-2" @click="clearField()">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
              <gmap-map
                :center="center"
                :zoom="13"
                style="width: 100%; height: 250px"
              >
                <GmapMarker
                  :key="index"
                  v-for="(m, index) in markers"
                  :position="m.position"
                  :clickable="false"
                  :draggable="false"
                />
              </gmap-map>
            </div>

            <div>
              <v-card-text>
                <v-form ref="form">
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-text-field
                        dense
                        v-model="povName"
                        label="Name"
                        counter="30"
                        :rules="[
                          (v) => !!v || 'required',
                          (v) =>
                            (!!v && v.length < 31) || 'Maximum 30 characters',
                        ]"
                        outlined
                        rounded
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        rounded
                        v-if="StorePOVsObj.action == 'EDIT'"
                        v-model="povDay"
                        dense
                        label="Eg: Day:1, Day:2..."
                        :rules="dayInpRules"
                        outlined
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-if="StorePOVsObj.action == 'EDIT'"
                        dense
                        v-model="povDescription"
                        counter="1000"
                        maxLength="1000"
                        label="Notes"
                        outlined
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </div>
            <div>
              <v-btn
                block
                dense
                rounded
                dark
                @click="validate_data()"
                :loading="loading"
                class="text-capitalize createEdit-pov-btn"
                >{{ StorePOVsObj.action == "EDIT" ? "Update" : "Create" }}
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-container>
    </v-dialog>
  </div>
</template>
<script>
/* eslint-disable */
import { edit_pov, pov_creation } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
import SnackBar from "@/components/Extras/SnackBar.vue";

export default {
  props: {
    povDialog: Boolean,
    StoreObj: Object,
    StorePOVsObj: Object,
  },
  components: {
    SnackBar,
  },
  data() {
    return {
      povName: "",
      povDay: undefined,
      geoLattitude: "",
      geoLongitude: "",
      loading: false,
      search: "",
      search1: "",
      currentPlace: null,
      dayInpRules: [(v) => v%1==0 || "Must be a number"],

      markers: [
        {
          position: {},
        },
      ],
      locationHead: "",
      center: { lat: 0, lng: 0 },
      defaultCenter: {
        lat: 0,
        lng: 0,
      },
      latt: "",
      longg: "",
      masterUsers: [],
      povName: "",
      povDescription: "",
      SnackBarComponent: {},
    };
  },
  watch: {
    povDialog(val) {
      if (val == true) {
        if (this.StorePOVsObj.action === "EDIT") {
          this.povName = this.StorePOVsObj.pov_name;
          this.povDay = this.StorePOVsObj.pov_duration_day;
          this.povDescription = this.StorePOVsObj.pov_notes;
          this.center.lat = parseFloat(this.StorePOVsObj.pov_latitude);
          this.center.lng = parseFloat(this.StorePOVsObj.pov_longitude);
          this.markers.push({
            position: {
              lat: parseFloat(this.StorePOVsObj.pov_latitude),
              lng: parseFloat(this.StorePOVsObj.pov_longitude),
            },
          });
        }
      }
    },
  },
  methods: {
    setPlace(place) {
      this.currentPlace = place;
      this.povName = place.name;
      (this.center.lat = this.currentPlace.geometry.location.lat()),
        (this.center.lng = this.currentPlace.geometry.location.lng());
      this.markers.push({
        position: {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        },
      });
    },

    clearField() {
      this.search = null;
      this.addMarker();
    },
    addMarker() {
      if (this.currentPlace) {
        // this.povName = this.currentPlace.formatted_address;
        this.markers = [];
        var marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.markers.push({ position: marker });
        (this.geoLattitude = marker.lat), (this.geoLongitude = marker.lng);
      }
    },

    validate_data() {
      if (this.$refs.form.validate()) {
        this.create_master_locations();
      }
    },

    CreatePovEmit(Toggle) {
      this.$emit("toSort", Toggle);
      this.$refs.form.reset();
      this.povName = "";
      this.markers = [];
      this.center = { lat: 0, lng: 0 };
    },

    callposition(latLng) {
      this.markers = [];
      var marker = {
        lat: latLng.latLng.lat(),
        lng: latLng.latLng.lng(),
      };
      this.markers.push({ position: marker });
      this.geoLattitude = marker.lat;
      this.geoLongitude = marker.lng;
    },

    async create_master_locations() {
      this.loading = true;
      let mutation_name =
        this.StorePOVsObj.action === "CREATE" ? pov_creation : edit_pov;
      let mutation_result =
        this.StorePOVsObj.action === "CREATE" ? "pov_creation" : "edit_pov";
      try {
        let result = await API.graphql(
          graphqlOperation(mutation_name, {
            input: {
              phone_number:
                this.$store.getters.GetUserDetails.agent_phone_number,
              travelogue_id: this.StoreObj.travelogue_id,
              pov_id:
                this.StorePOVsObj.action == "EDIT"
                  ? this.StorePOVsObj.pov_id
                  : undefined,
              pov_name: this.povName,
              pov_duration_day:
                this.StorePOVsObj.action == "EDIT" ? this.povDay : undefined,
              pov_latitude: this.center.lat.toString(),
              pov_longitude: this.center.lng.toString(),
              pov_notes:
                this.StorePOVsObj.action == "EDIT"
                  ? this.povDescription
                  : undefined,
            },
          })
        );
        var response = JSON.parse(result.data[mutation_result]);
        this.loading = false;
        if (response.Status == "SUCCESS") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            SnackbarText: response.Message,
          };
          this.CreatePovEmit(2);
          // this.$emit("successMsg", response.Message);
          this.$refs.form.reset();
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            SnackbarText: response.Message,
          };
          // this.$emit("errorMsg", response.Message);
        }
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: error.errors[0].message,
        };
        // this.$emit("errorMsg", error.errors[0].message);
        this.loading = false;
      }
    },
  },
};
</script>

<!-- <style scoped>
.create-dialog-head {
  position: absolute;
  top: 20px;
  left: 20px;
}

.createpov-dialog-close {
  position: absolute;
  top: 20px;
  right: 20px;
}

.createEdit-pov-btn{
  background: linear-gradient(to bottom, #33bbc5, #e91e63);
}
</style> -->
