import { travelogS3URL } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
import axios from "axios";

export const GenerateS3URL = {
  data: () => ({}),
  methods: {
    async GenerateS3URLMethods(key, file) {
      try {
        let result = await API.graphql(
          graphqlOperation(travelogS3URL, {
            input: {
              key: key,
            },
          })
        );
        let ResultObj = JSON.parse(result.data.travelogS3URL);
        if (ResultObj.status === "Success") {
          return this.UploadS3method(ResultObj.message, key, file);
        }
      } catch (error) {
      }
    },

    async UploadS3method(UploadURL, key, file) {
      try {
        // let config = {
        //   method: "put",
        //   maxBodyLength: Infinity,
        //   url: UploadURL,
        //   headers: {
        //     "Content-Type": file.type,
        //   },
        //   data: file,
        // };
        let result = await axios.put(UploadURL, file, {
          headers: {
            "Content-Type": file.type,
          },
        });
        if (result.status === 200 && result.statusText === "OK") {
          let Object = {
            status: "SUCCESS",
            file_URL: `https://travelogue-bucket-1.s3.ap-south-1.amazonaws.com/${key}`,
          };
          return Object;
        }
      } catch (error) {
        console.log("Error in Upload", error);
      }
    },
  },
};

