<template>
  <div>
    <SnackBar :SnackBarComponent="SnackBarComponent" />
    <v-dialog
      :width="500"
      v-model="DeletePovDialog"
      max-width="500px"
      persistent
    >
      <v-container fluid class="pa-0">
        <v-card elevation="0" class="pa-10 ma-0">
          <div class="delete-dialog-heading">
            <h1>Delete POV</h1>
          </div>
          <div class="absolute top right">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  class="delete-dialog-close-btn"
                  icon
                  @click="DeleteDialogEmit(1)"
                  v-on="on"
                  ><v-icon>mdi-close-circle</v-icon></v-btn
                >
              </template>
              <span>Close</span>
            </v-tooltip>
          </div>
          <v-card-text class="mt-10">
            <p class="text-center font-weight-bold">
              Are you sure want to delete "{{ StorePOVsObj.pov_name }}"?
            </p>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              block
              @click="deletePov()"
              :loading="loading"
              class="text-capitalize dialog-delete-btn"
              depressed
              rounded
              >Delete</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-container>
    </v-dialog>
  </div>
</template>

<script>
import SnackBar from "@/components/Extras/SnackBar.vue";
import { API, graphqlOperation } from "aws-amplify";
import { delete_pov } from "@/graphql/mutations.js";
export default {
  props: {
    DeletePovDialog: Boolean,
    StorePOVsObj: Object,
  },
  components: {
    SnackBar,
  },
  data: () => ({
    povName: "",
    loading: false,
    SnackBarComponent: {},
  }),
  methods: {
    async deletePov() {
      this.loading = true;
      try {
        let result = await API.graphql(
          graphqlOperation(delete_pov, {
            input: {
              travelogue_id: this.StorePOVsObj.travelogue_id,
              pov_id: this.StorePOVsObj.pov_id,
            },
          })
        );
        var response = JSON.parse(result.data.delete_pov);
        if (response.Status == "SUCCESS") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            SnackbarText: response.Message,
          };
          this.loading = false;
          this.DeleteDialogEmit(2);
        }
      } catch (error) {
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: error.errors[0].message,
        };
      }
    },

    DeleteDialogEmit(Toggle) {
      this.$emit("clicked", Toggle);
    },
  },
};
</script>

<style scoped>
.dialog-delete-btn {
  background: linear-gradient(to bottom, #33bbc5, #e91e63);
  color: white;
}

.delete-dialog-heading {
  position: absolute;
  top: 20px;
  left: 20px;
}

.delete-dialog-close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
}
</style>
